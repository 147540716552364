<template>
  <v-lazy>
    <div>
      <h2>{{ translate("Client") }}</h2>
      <v-data-table dense :items-per-page="20" :headers="clientTableHeaders" :items="[order]" :hide-default-footer="true"
        item-key="name"></v-data-table>
      <template>
        <l-map style="height: 200px; width: 100%" class="mx-2 d-flex align-end flex-column" :zoom="16"
          :center="[order.client_lat, order.client_lng]">
          <v-btn style="z-index: 5000; margin: 10px" class="mx-2" fab color="primary" target="_blank"
            :href="`https://www.google.com/maps/search/?api=1&query=${order.client_lat},${order.client_lng}`">
            <v-icon dark> mdi-google-maps </v-icon>
          </v-btn>
          <l-polyline :lat-lngs="[
            [
              { lat: order.client_lat, lng: order.client_lng },
              { lat: order.magazine_lat, lng: order.magazine_lng },
            ],
          ]" @click="alert(item)" />
          <l-tile-layer :url="url"></l-tile-layer>
          <l-marker :lat-lng="[order.client_lat, order.client_lng]">
            <l-tooltip :options="{ permanent: true }">
              {{ order.client_name }}
            </l-tooltip>
          </l-marker>
          <l-marker :lat-lng="[order.magazine_lat, order.magazine_lng]">
            <l-icon :icon-size="dynamicSize" :icon-anchor="dynamicAnchor" icon-url="apartment.png" />

            <l-tooltip :options="{ permanent: true }">
              {{ order.magazine_name }}
            </l-tooltip>
          </l-marker>
        </l-map>
      </template>

      <br />
      <br />
      <br />
      <h2>{{ translate("Order Info") }}</h2>
      <v-divider></v-divider>
      <v-row dense>
        <v-col><span>{{ translate("Created at") }}</span>
          <span>
            <h3>{{ formatDate(order.order_created_at) }}</h3>
          </span></v-col>
        <v-col><span>{{ translate("Created By") }}</span>
          <span>
            <h3>{{ order_created_by_name }}</h3>
          </span>
        </v-col>

        <v-col>
          <span>{{ translate("Deadline at") }}</span>
          <span>
            <h3>{{ formatDate(order.deadline) }}</h3>
          </span>
          <span v-if="order.delay_reason != null">{{ translate("Delay Reason") }}</span>
          <span>
            <h3>{{ order.delay_reason }}</h3>
          </span>
        </v-col>
        <v-col v-if="order.has_invoice == 1">
          <v-btn style="margin-top: 5px" dense color="warning" @click="openInvoice()">Invoice</v-btn>
        </v-col>
        <v-col v-if="order.has_invoice != 1">
          <v-select style="padding-top: 5px" v-if="isSalesAgent == false" outlined hide-details
            :class="selectedDesigners.length == 0 ? '' : 'shrinked'" :label="translate('Choose Devpos')" dense filled
            item-text="text" item-value="value" v-model="devPos" v-on:change="devposChosen" :items="[
              {
                text: 'Devpos',
                value: 1,
              },
              {
                text: 'No Devpos',
                value: 0,
              },
            ]"></v-select></v-col>

        <v-col v-if="order.driver_picked_up_at != null"><span>{{ translate("Driver Picked up at") }}</span>
          <span>
            <h3>{{ formatDate(order.driver_picked_up_at) }}</h3>
          </span></v-col>
        <v-col v-if="order.driver_delivered_at != null"><span>{{ translate("Driver Delivered at") }}</span>
          <span>
            <h3>{{ formatDate(order.driver_delivered_at) }}</h3>
          </span></v-col>
        <v-col v-if="order.order_status == 'returned'"><span>{{ translate("Return reason") }}</span>
          <span>
            <h3>{{ order.reason }}</h3>
          </span></v-col>

        <v-col style="margin: 5px" v-if="isSalesAgent == false">
          <v-btn v-if="showBack" @click="goBack()" fab small class="mx-2" color="error">
            <v-icon dark> mdi-chevron-left </v-icon>
          </v-btn>
          <span>{{ translate("Status") }} </span>
          <span class="font-weight-medium v-chip v-size--small" :class="order.color"><span class="v-chip__content">
              {{ orderStatusToText(order.order_status) }}
            </span></span>
          <v-btn v-if="showFront" :disabled="frontDisabled" @click="goFront" class="mx-2" fab small color="success">
            <v-icon dark> mdi-chevron-right </v-icon>
          </v-btn>
          <span style="color: red"> {{ order.error }}</span>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <br />
      <v-row dense> </v-row>
      <v-row>
        <div v-for="image in images" :key="image.id">
          <div style="
              overflow: visible;
              position: relative;
              min-width: 110px;
              max-width: 110px;
              min-height: 110px;
              max-height: 110px;
              margin: 5px;
              padding: 5px;
              border-style: dashed;
            ">
            <a :href="image.image_url" target="_blank"><v-img v-if="isFileImage(image)" style="height: 95px; width: 100%"
                :src="image.image_url"></v-img>
              <span v-else style="
                  -webkit-line-clamp: 7;
                  display: -webkit-box;
                  line-height: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  line-break: anywhere;
                ">
                {{ image.filename }}</span>
            </a>
          </div>
        </div>
      </v-row>
      <br />
      <h3>{{ translate("Service Files") }} <a @click="openServiceImage">upload</a></h3>
      <v-data-table :items-per-page="20" dense :headers="[
        { text: this.translate('ID'), value: 'id' },
        { text: this.translate('File'), value: 'filename' },
        { text: this.translate('Actions'), value: 'actions' },
      ]" :items="serviceImages" :hide-default-footer="true" item-key="name">
        <template v-slot:item.filename="{ item }"><a :href="getDownloadServiceFileUrlLocal(item.filename)"
            target="_blank">{{
              item.filename
            }}</a></template>
        <template v-slot:item.actions="{ item }">
          <v-icon @click.stop="deleteServiceFile(item.id)" small style="color: var(--v-error-base)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <h3>{{ translate("Stocks") }}</h3>
      <v-data-table :items-per-page="20" dense :headers="stocksTableHeaders" :items="stocks" :hide-default-footer="true"
        item-key="name">
        <template v-slot:item.image="{ item }">
          <div style="
              overflow: visible;
              position: relative;
              min-width: 55px;
              max-width: 55px;
              min-height: 55px;
              max-height: 55px;
              margin: 5px;
              padding: 5px;
              border-style: dashed;
            ">
            <a :href="item.image_url" target="_blank"><v-img style="height: 45px; width: 100%"
                :src="item.image_url"></v-img></a>
          </div>
        </template>

        <template v-slot:item.amount="{ item }">{{ item.amount }} {{ item.measurement_name }}</template>
        <template v-slot:item.quantity_price="{ item }">{{ item.quantity_price }} {{ currency }}</template>
        <template v-slot:item.tax="{ item }">{{ item.tax }}%</template>
        <template v-slot:item.discount="{ item }">{{ item.discount }}%</template>
        <template v-slot:item.total="{ item }">{{ calculateTotal(item) }} {{ currency }}</template>
      </v-data-table>
      <h3 v-if="services.length != 0">{{ translate("Services") }}</h3>
      <v-data-table :items-per-page="20" dense v-if="services.length != 0" :headers="servicesTableHeaders"
        :items="services" :hide-default-footer="true" item-key="name">
        <template v-slot:item.price="{ item }">{{ item.price }} {{ currency }}</template>
        <template v-slot:item.total="{ item }">{{ item.price * item.amount }} {{ currency }}</template>
      </v-data-table>
      <div style="padding-left: 20px">
        <h4 class="text-decoration-none" style="font-weight: 500">
          {{ translate("Sub Total") }}: {{ getSubTotal }} {{ currency }}
        </h4>
        <h4 class="text-decoration-none" style="font-weight: 500; padding: 0">
          {{ translate("Total Discount") }}: {{ getDiscount }} {{ currency }}
        </h4>
        <h4 class="text-decoration-none" style="font-weight: 500; margin: 0">
          {{ translate("Total Tax") }}: {{ getTax }} {{ currency }}
        </h4>
        <h4 class="text-decoration-none" style="font-weight: 500">
          {{ translate("Delivery Fee") }}: {{ get_delivery_fee }} {{ currency }}
        </h4>
        <h4 class="text-decoration-none" style="font-weight: 500">
          {{ translate("Services") }}: {{ getServiceTotal }} {{ currency }}
        </h4>
        <h4 class="text-decoration-none" style="font-weight: 500">
          {{ translate("Profit") }}: {{ getProfit }} {{ currency }}
        </h4>
      </div>
      <v-divider></v-divider>
      <div class="d-flex justify-space-between flex-wrap" style="padding-left: 10px">
        <h3 style="font-weight: 600">
          {{ translate("Grand Total") }}: {{ getGrandTotal }} {{ currency }}
        </h3>
      </div>

      <div>
        <br />
        <div style="display: flex; margin-bottom: 5px">
          <span>
            <h2>{{ translate("Designers") }}</h2>
          </span>
          <span style="min-width: 10px"></span>
          <span><v-select v-if="isSalesAgent == false" outlined hide-details
              :class="selectedDesigners.length == 0 ? '' : 'shrinked'" :label="translate('Designers')" dense filled
              item-text="text" item-value="value" v-model="selectedDesigners" multiple chips
              v-on:change="designersChanged" :items="getDesigners"></v-select></span>
        </div>
        <v-divider></v-divider>
        <br />
        <v-data-table :items-per-page="20" v-if="designers.length != 0" dense :headers="designerTableHeaders"
          :items="designers" :hide-default-footer="true" item-key="name">
          <template v-slot:item.status="{ item }">
            <span class="font-weight-medium v-chip v-size--small" :class="item.status == 0 ? 'error' : 'success'"><span
                class="v-chip__content">
                {{ item.status == 0 ? "Designing" : "Done" }}
              </span></span>
          </template></v-data-table>
      </div>
      <div>
        <br />
        <div style="display: flex; margin-bottom: 5px">
          <span>
            <h2>{{ translate("Productions") }}</h2>
          </span>
          <span style="min-width: 10px"></span>
          <span><v-select v-if="isSalesAgent == false" :class="selectedProductions.length == 0 ? '' : 'shrinked'" outlined
              hide-details :label="translate('Productions')" dense filled item-text="text" item-value="value"
              v-model="selectedProductions" chips multiple v-on:change="productionsChanged"
              :items="getProductions"></v-select></span>
        </div>
        <v-divider></v-divider>
        <br />
        <v-data-table :items-per-page="20" v-if="production.length != 0" dense :headers="productionTableHeaders"
          :items="production" :hide-default-footer="true" item-key="name"></v-data-table>
      </div>
      <div>
        <br />
        <h2>{{ translate("Sales Post") }}</h2>
        <v-divider></v-divider>
        <div style="display: flex; margin-top: 5px">
          <v-chip style="margin-top: 5px" :color="order.sales_post == null ? 'error' : 'primary'">
            {{ order.sales_post == null ? translate("Sales Post is not chosen") : "" }}
            {{
              order.sales_post == "courier"
              ? order.driver_name
              : textToSalesPost(order.sales_post)
            }}</v-chip>
          <span style="width: 10px"></span>
          <span>
            <v-select v-if="isSalesAgent == false" style="min-width: 150px" filled :disabled="['production', 'designing', 'returned', 'choose_agents'].includes(
              order.order_status
            )
                ? false
                : true
              " outlined :label="translate('Sales Post')" dense v-model="sales_post" :items="getDrivers"
              @input="salesPostChanged"></v-select>
          </span>
        </div>

        <v-data-table :items-per-page="20" v-if="order.driver_id != undefined" dense :headers="driverTableHeaders"
          :items="[order]" :hide-default-footer="true" item-key="name"></v-data-table>
      </div>
      <div class="text-right">
        <v-btn v-if="isSalesAgent == true && order.order_status == 'returned'" @click="goFront()" class="success">
          Proceed</v-btn>
      </div>
      <input ref="serviceImageuploader" class="d-none" type="file" accept="" @change="uploadServiceImage" />
    </div>
  </v-lazy>
</template>
<script>
import { LMap, LTileLayer, LMarker, LTooltip, LPolyline, LIcon } from "vue2-leaflet";
import { Icon } from "leaflet";
import { mdiGoogle } from "@mdi/js";

import axios from "axios";
import {
  assignOrderToDriver,
  changeOrderStatus,
  getOrders,
  insertNewOrderImage,
  removeOrderImage,
  getUsers,
  moveOrder,
  getOrderInfo,
  getApprovedUsers,
  changeOrderSalesPost,
  changeOrderDesigners,
  changeOrderProductions,
  devpos_chosen,
  openOrderInvoice,
  getMagazines,
  getDownloadServiceFileUrl,
  deleteServiceImage,
  uploadServiceImage,
} from "@/ApiManager";
import VueHtml2pdf from "vue-html2pdf";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  props: ["order", "currency"],

  methods: {
    openServiceImage() {
      this.$refs.serviceImageuploader.click();
    },
    async uploadServiceImage(data) {
      this.$store.state.loading = true;

      var file = data.target.files[0];
      await uploadServiceImage(file, this.order.id);
      await this.getNewData();
      this.$store.state.loading = false;
    },
    getDownloadServiceFileUrlLocal(filename) {
      return getDownloadServiceFileUrl(this.order.id, filename);
    },
    async deleteServiceFile(id) {
      this.$store.state.loading = true;

      await deleteServiceImage(id);
      await this.getNewData();

      this.$store.state.loading = false;
    },
    isFileImage(file) {
      const fileType = file["filetype"];
      const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
      if (validImageTypes.includes(fileType)) {
        return true;
      }
      return false;
    },
    async openInvoice() {
      const externalPageUrl = await openOrderInvoice(this.order.id);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain; charset=UTF-8");
      fetch(externalPageUrl, myHeaders)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          let decoder = new TextDecoder("UTF-8");
          let text = decoder.decode(buffer);
          const externalPage = window.open("", "_blank");
          externalPage.document.write(text);
          externalPage.document.close();
          externalPage.print();
        });
    },
    async devposChosen() {
      this.$store.state.loading = true;
      await devpos_chosen(this.order.id, this.devPos);
      await this.getNewData();
      this.$store.state.loading = false;
    },
    async designersChanged() {
      console.log("now4");

      this.$store.state.loading = true;
      await changeOrderDesigners(
        this.order.id,
        this.selectedDesigners,
        this.designers.map((designer) => designer.id)
      );
      console.log("now3");
      await this.getNewData();
      this.$store.state.loading = false;
    },
    async productionsChanged() {
      this.$store.state.loading = true;
      await changeOrderProductions(
        this.order.id,
        this.selectedProductions,
        this.production.map((production) => production.id)
      );
      await this.getNewData();
      this.$store.state.loading = false;
    },
    async salesPostChanged() {
      this.$store.state.loading = true;
      await changeOrderSalesPost(this.order.id, this.sales_post);
      await this.getNewData();
      this.$store.state.loading = false;
    },
    async goBack() {
      this.$store.state.loading = true;
      console.log(this.order);
      await moveOrder(1, this.order.order_status, this.order.id, this.order.sales_post);
      await this.getNewData();

      this.$store.state.loading = false;
    },
    async goFront() {
      this.$store.state.loading = true;
      await moveOrder(0, this.order.order_status, this.order.id, this.order.sales_post);
      await this.getNewData();

      this.$store.state.loading = false;
    },
    async removeOrderImage(image) {
      this.$store.state.loading = true;

      await removeOrderImage(image.id);
      await this.getNewData();

      this.$store.state.loading = false;
    },

    async openImagePrompt() {
      this.$refs.uploader.click();
    },
    async orderInfo() {
      var result = await getOrderInfo(this.order.id);
      console.log("order is loaded");
      this.production = result.production;
      this.images = result.images;
      this.designers = result.designers;
      this.stocks = result.stocks;
      this.services = result.services;
      this.serviceImages = result.serviceImages;

      this.devPos = this.order.is_devpos;
      this.selectedDesigners = this.designers.map((designer) => designer.id);
      this.selectedProductions = this.production.map((production) => production.id);
      return;
    },
    async uploadImage(data) {
      this.$store.state.loading = true;

      var file = data.target.files[0];
      await insertNewOrderImage(file, this.order.id);
      this.$store.state.loading = false;
      this.getNewData();
    },

    async getNewData() {
      this.$store.state.loading = true;
      await this.orderInfo();

      this.$emit("setNewData");

      this.$store.state.loading = false;
    },
    async orderStatusChanged(orderStatus) {
      this.$store.state.loading = true;

      var response = await changeOrderStatus(this.order.id, orderStatus);
      this.getNewData();
    },

    calculateTotal(stock) {
      var price = 0;
      //get total price
      price = stock.amount * stock.quantity_price;
      //calculate discount
      price = price - (price * stock.discount) / 100;

      //calculate tax
      price = price + (price * stock.tax) / 100;
      return price;
    },
  },
  data() {
    return {
      serviceImages: [],
      selectedDesigners: [],
      selectedProductions: [],
      invoiceText: "",
      sales_post: null,
      users: [],
      order_created_by_name: null,
      images: [],
      stocks: [],
      services: [],
      production: [],
      designers: [],
      devPos: null,
      magazines: null,
      file: null,
      orderStatuses: [
        { text: this.translate("Processing"), value: "processing" },
        { text: this.translate("Returned"), value: "returned" },
        { text: this.translate("Waiting for a Driver"), value: "waiting_for_a_driver" },
        { text: this.translate("Client Picking Up"), value: "client_picking_up" },
        { text: this.translate("Company Delivering"), value: "company_delivering" },
        { text: this.translate("Driver Picking up"), value: "driver_picking_up" },
        { text: this.translate("Driver Delivering"), value: "driver_delivering" },
        { text: this.translate("Completed"), value: "completed" },
      ],
      drivers: [],
      selected_driver: this.order.driver_name,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",

      zoom: 50,
      markerLatLng: [0, 0],
      leaflet_key: 0,
      clientTableHeaders: [
        { text: "ID", value: "client_id" },

        { text: this.translate("Name"), value: "client_name" },
        { text: this.translate("Phone Number"), value: "client_phone" },
        { text: this.translate("Address"), value: "client_address" },
      ],
      driverTableHeaders: [
        { text: "ID", value: "driver_id" },
        { text: this.translate("Name"), value: "driver_name" },
        { text: this.translate("Phone Number"), value: "driver_phone_number" },
      ],
      designerTableHeaders: [
        { text: "ID", value: "id" },
        { text: this.translate("Name"), value: "name" },
        { text: this.translate("Phone Number"), value: "phone_number" },
        { text: this.translate("Status"), value: "status" },
      ],
      productionTableHeaders: [
        { text: "ID", value: "id" },
        { text: this.translate("Name"), value: "name" },
        { text: this.translate("Phone Number"), value: "phone_number" },
      ],
      stocksTableHeaders: [
        { text: this.translate("Image"), value: "image" },

        { text: this.translate("Product"), value: "product_name" },
        { text: this.translate("Category"), value: "category_name" },
        { text: this.translate("Machine"), value: "machine_name" },

        { text: this.translate("Quantity Price"), value: "quantity_price" },
        { text: this.translate("Amount"), value: "amount" },
        { text: this.translate("Discount"), value: "discount" },
        { text: this.translate("Tax"), value: "tax" },
        { text: this.translate("Total"), value: "total" },
      ],
      servicesTableHeaders: [
        { text: this.translate("Name"), value: "name" },
        { text: this.translate("Text"), value: "text" },
        { text: this.translate("Notes"), value: "notes" },

        { text: this.translate("Price"), value: "price" },
        { text: this.translate("Amount"), value: "amount" },
        { text: this.translate("Total"), value: "total" },
      ],
    };
  },
  computed: {
    isSalesAgent() {
      return JSON.parse(localStorage.getItem("user")).role == "sales_agent";
    },
    getDesigners() {
      var designers = [];
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].role == "designer")
          designers.push({ text: this.users[i].name, value: this.users[i].id });
      }
      return designers;
    },
    getProductions() {
      var designers = [];
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].role == "production")
          designers.push({ text: this.users[i].name, value: this.users[i].id });
      }
      return designers;
    },
    getDrivers() {
      var drivers = [];
      drivers.push({ value: "client_picks_up", text: "Shop Peshkopi" });
      drivers.push({ value: "delivery_company", text: "Addex" });
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].role == "driver")
          drivers.push({ value: this.users[i].id, text: this.users[i].name });
      }

      return drivers;
    },
    showBack() {
      return this.order.order_status != "choose_agents";
    },
    frontDisabled() {
      if (
        (this.order.sales_post == null && this.order.order_status == "production") ||
        (this.order.order_status == "choose_agents" &&
          (this.designers.length == 0 || this.production.length == 0)) ||
        (this.order.order_status == "choose_devpos" && this.order.is_devpos == null)
      ) {
        return true;
      }

      return false;
    },
    showFront() {
      return this.order.order_status != "delivered";
    },

    dynamicSize() {
      return [this.iconSize * 0.5, this.iconSize * 0.5];
    },
    dynamicAnchor() {
      return [this.iconSize * 0.5, this.iconSize * 0.5];
    },
    getSubTotal() {
      var price = 0;
      for (var i = 0; i < this.stocks.length; i++) {
        price += this.stocks[i].quantity_price * this.stocks[i].amount;
      }
      return price;
    },
    getServiceTotal() {
      var service = 0;
      for (var i = 0; i < this.services.length; i++) {
        service += this.services[i].price * this.services[i].amount;
      }
      return service;
    },
    getProfit() {
      //first calculate the buy price of each order INCLUDING taxes
      var profit = 0;
      for (var i = 0; i < this.order.length; i++) {
        var stock = this.order.stocks[i];
        var order_buy_price = stock.order_buy_price + stock.order_buy_price * 0.2;
        var buy_cost_total = order_buy_price * stock.amount;
        var price = 0;
        //get total price
        price = stock.amount * stock.quantity_price;
        //calculate discount
        price = price - (price * stock.discount) / 100;
        profit += price - buy_cost_total;
      }

      return profit + this.getServiceTotal;
    },
    getDiscount() {
      var price = 0;
      for (var i = 0; i < this.stocks.length; i++) {
        var cost = this.stocks[i].quantity_price * this.stocks[i].amount;
        price += (cost * this.stocks[i].discount) / 100;
      }
      return price;
    },
    getTax() {
      var price = 0;
      for (var i = 0; i < this.stocks.length; i++) {
        var cost = this.stocks[i].quantity_price * this.stocks[i].amount;
        cost -= (cost * this.stocks[i].discount) / 100;
        price += (cost * this.stocks[i].tax) / 100;
      }
      return price;
    },
    get_delivery_fee() {
      return this.order.delivery_fee;
    },
    getGrandTotal() {
      var price = 0;
      for (var i = 0; i < this.stocks.length; i++) {
        var stock = this.stocks[i];
        //get total price
        var cost = stock.amount * stock.quantity_price;
        //calculate discount
        if (stock.discount != 0) {
          cost = cost - (cost * stock.discount) / 100;
        }
        //calculate tax
        if (stock.tax != 0) {
          cost = cost + (cost * stock.tax) / 100;
        }
        price += cost;
      }
      return parseInt(price) + parseInt(this.order.delivery_fee) + this.getServiceTotal;
    },
  },
  components: {
    LMap,
    mdiGoogle,
    LTileLayer,
    LMarker,
    LTooltip,
    LPolyline,
    LIcon,
    VueHtml2pdf,
  },
  async created() {
    this.$store.state.loading = true;
    var _drivers = await getUsers();
    for (var i = 0; i < _drivers.length; i++) {
      this.drivers.push({
        text: _drivers[i].name,
        value: _drivers[i],
      });
      if (_drivers[i].id == this.order.created_by_user_id) {
        this.order_created_by_name = _drivers[i].name;
      }
    }
    getApprovedUsers().then((response) => {
      this.users = response;
    });
    await this.orderInfo();

    this.$store.state.loading = false;
  },
};
</script>
<style>
.v-input.shrinked .v-input__control .v-text-field__details .v-messages {
  height: 0px;
}

.customBtn {
  height: 25px;
  width: 25px;
}

.v-input.shrinked .v-select__selections input {
  display: none;
  width: 5px;
}
</style>
